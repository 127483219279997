<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style>
::-webkit-scrollbar {
  display: none;
}
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ImageLogin {
  content: "" !important;
  /* border-radius: 0 100px 100px 0 !important; */
  z-index: 0 !important;
  height: 100vh !important;
  width: 100% !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-image: url("assets/bg2.png") !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  overflow: hidden !important;
  padding: 50px !important;
  padding-left: 200px !important;
}
.secondClass {
  justify-content: center !important;
  align-items: center !important;
  max-height: 680px;
  height: 50%;
  border-radius: 30px;
  width: 100%;
  padding-top: 5% !important;
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.492);
}
.LoginLeftSideClass {
  content: "" !important;
  border-radius: 0 100px 100px 0 !important;
  z-index: 0 !important;
  height: 100vh !important;
  width: 100% !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-image: url("assets/bg1.png") !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  overflow: hidden !important;
  padding: 50px !important;
  padding-left: 200px !important;
}
.LoginLeftSideClass .CardContent {
  /* display: flex; */
  flex-direction: column;
  justify-content: center; /* Align items to the top */
  align-items: center !important; /* Center items horizontally */
  max-height: 680px;
  height: 100%;
  padding: 60px 50px;
  border-radius: 30px;
  max-width: 515px;
  width: 100%;
  padding-top: 30% !important;
  background-color: rgba(0, 0, 0, 0.7);
}
.FontFamilyVariant1 {
  font-family: Goudy Old Style !important;
  font-weight: 600;
  font-size: 20px !important;
}
.FontFamilyVariant2 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 600;
  font-size: 36px !important;
}
.FontFamilyVariant3 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 700;
  font-size: 18px !important;
}
.FontSize8px {
  font-size: 8px !important;
}
.FontSize10px {
  font-size: 10px !important;
}
.FontSize12px {
  font-size: 12px !important;
}
.FontSize14px {
  font-size: 14px !important;
}
.FontSize16px {
  font-size: 16px !important;
}
.FontSize18px {
  font-size: 18px !important;
}
.FontSize20px {
  font-size: 20px !important;
}
.FontSize22px {
  font-size: 22px !important;
}
.FontSize24px {
  font-size: 24px !important;
}
.OTPField .v-otp-input__content input {
  /* padding: 0 !important; */
  background-color: #fff !important;
}
.maxWidthVariant1 {
  max-width: 150px !important;
}
.v-otp-input {
  display: block !important;
  justify-content: flex-start !important;
}
.CursorVariant {
  cursor: pointer !important;
}
.maxWidthVariant {
  max-width: 200px !important;
}
.btn-item {
  color: black !important;
  transition: background-color 0.3s ease !important;
}

.btn-item:hover {
  color: white !important;
  background-color: rgb(253, 54, 54) !important;
}
.borderRadius20px {
  border-radius: 8px !important;
}
.borderCustom {
  border-color: black !important;
}
.tableVariant th {
  color: #262a2a !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.tableVariant td {
  height: 40px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: sans-serif !important;
  color: #6f6f6f !important;
  line-height: 1.5 !important;
}
.custom-select {
  border-radius: 25px !important;
  max-height: 30px !important;
}
.RouteName {
  font-weight: 700 !important;
  color: #262a2a !important;
  font-size: 18px !important;
}
.profilename {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #262a2a !important;
  margin-bottom: 2px !important;
}
.v-navigation-drawer--left {
  top: 0;
  left: 0;
  right: auto;
  border-right-width: 0 !important;
}
.StickyDialogClass {
  align-content: flex-start !important;
}
.ImageRadius {
  border-radius: 100px !important;
  border-color: black 1px solid !important;
}
.ProfileImageRadius {
  border-radius: 150px !important;
}
.borderradiusImg {
  border-radius: 50px !important;
}
.maxWidthVariant230 {
  min-width: 250px !important;
}
/* th,
td {
  border-right: 1px solid #e3e6f0;
  border-top: 1px solid #e3e6f0;
  border-left: 1px solid #e3e6f0;
  border-bottom: 1px solid #e3e6f0;
} */
</style>

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    CurrentUser_Details: {},
  },
  getters: {
    get_currentuser_details: (state) => {
      return state.CurrentUser_Details;
    },
  },
  mutations: {
    SET_CURRENT_USER(state, current_user) {
      state.CurrentUser_Details = current_user;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
const myCustomTheme = {
  dark: false,
  colors: {
    primary: "#62526f",
    secondary: "#424242",
    accent: "#82B1FF",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
    background: "#f8f8fb",

    yellowColorVariant1: "#fac62a",
    yellowColorVariant2: "#fdedbf",
    yellowColorVariant3: "#c89e21",

    redColorVariant1: "#ff1919",
    redColorVariant2: "#FF5C5C",
    redColorVariant3: "#ba0f30",
    redColorVariant4: "#2e2b37",
    redColorVariant5: "#f2927a",

    greenColorVariant1: "#103137",
    greenColorVariant2: "#09c07b",

    blueColorVariant1: "#e5e8ed",
    blueColorVariant2: "#24156d",
    blueColorVariant3: "#004aad",
    blueColorVariant4: "#4a5e76",
    blueColorVariant5: "#017e65",
    blueColorVariant6: "#03fcca",
    blueColorVariant7: "#9bb8ed",
    blueColorVariant8: "3c2371",

    greyColorVariant1: "#e5e5e5",
    greyColorVariant2: "#efefdo",
    greyColorVariant3: "#e3dddc",
    greyColorVariant4: "#f2f2f2",
    greyColorVariant5: "#e6e7e9",
    greyColorVariant6: "#ced9e4",
    greyColorVariant7: "656262",

    blackVariant1: "#323232",
    blackVariant2: "#191919",
    blackVariant3: "#111c2d",
    blackVariant4: "#9fa4ab",
    blackVariant5: "#283242",
    blackVariant6: "#8d8f8f",
  },
};

export default createVuetify({
  theme: {
    defaultTheme: "myCustomTheme",
    themes: {
      myCustomTheme,
    },
  },
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
});

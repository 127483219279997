<template>
  <div>
    <Snackbar :SnackbarComponent="SnackbarComponent" />
    <v-row no-gutters>
      <v-col cols="12" md="7">
        <div class="LoginLeftSideClass">
          <div class="CardContent">
            <v-img max-height="100px" src="@/assets/logo.png" />
            <div class="text-center text-white FontFamilyVariant1 mt-4">
              AI Platform to Monitor Heart Health For Everyone!
            </div>
            <div class="text-center text-white FontFamilyVariant1 mt-4">
              Living Your Best Life.
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <v-row
          align="center"
          justify="center"
          :style="{ height: `${cardHeight - 100}px` }"
        >
          <v-col cols="12" class="px-10">
            <div class="FontFamilyVariant2 mx-10">Log In</div>
            <v-form ref="autoFocusOtpField" class="pt-8">
              <div class="FontFamilyVariant3 mx-10">Email Address</div>
              <v-text-field
                variant="outlined"
                density="compact"
                append-inner-icon="mdi-at"
                placeholder="Enter your email"
                class="mx-10 mt-2"
                :rules="[
                  (v) => !!v || 'required',
                  (v) => /.+@.+\..+/.test(v) || 'Email must be Valid',
                  (v) => !!v || 'required',
                ]"
                v-model="Login.email_id"
                @keydown.enter="
                  signInMethod(IsOTPEnabled ? 'VERIFY_OTP' : 'SEND_OTP')
                "
              ></v-text-field>
              <div
                v-if="IsOTPEnabled == true"
                class="mx-10 mt-2 text-left FontSize12px font-weight-bold"
              >
                You will receive an OTP in registered email for verification.
              </div>
              <v-otp-input
                v-if="IsOTPEnabled == true"
                class="OTPField mx-8"
                v-model="Login.otp"
              ></v-otp-input>
            </v-form>
            <v-card-actions class="mt-5">
              <v-btn
                class="mx-10"
                variant="flat"
                color="primary"
                :loading="loginLoader"
                @click="signInMethod(IsOTPEnabled ? 'VERIFY_OTP' : 'SEND_OTP')"
                >{{ IsOTPEnabled ? "Verify OTP" : "Login" }}</v-btn
              >
            </v-card-actions>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useDisplay } from "vuetify";
import { signIn, confirmSignIn, signOut } from "aws-amplify/auth";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  components: { Snackbar },
  data: () => ({
    cardHeight: 0,
    Login: {
      email_id: "",
      otp: "",
    },
    SnackbarComponent: {},
    IsOTPFieldEnabled: false,
    loginLoader: false,
    IsOTPEnabled: false,
  }),
  mounted() {
    const display = useDisplay();
    this.cardHeight = display.height._object.height;
  },
  methods: {
    async validateMethod() {
      const { valid } = await this.$refs.autoFocusOtpField.validate();
      if (valid) {
        this.signInMethod(this.IsOTPEnabled ? "VERIFY_OTP" : "SEND_OTP");
      } else if (this.Login.email_id == "" && this.IsOTPEnabled == false) {
        this.SnackbarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          timeout: 2000,
          SnackbarText: `Enter valid EmailId`,
        };
      } else if (this.Login.otp == "" && this.IsOTPEnabled) {
        this.SnackbarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          timeout: 2000,
          SnackbarText: `Enter OTP`,
        };
      }
    },
    async signInMethod(action) {
      switch (action) {
        case "SEND_OTP":
          try {
            this.loginLoader = true;
            this.user = await signIn({
              username: this.Login.email_id.toLocaleLowerCase(),
              options: {
                authFlowType: "CUSTOM_WITHOUT_SRP",
              },
            });
            if (
              this.user.nextStep.signInStep ==
              "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
            ) {
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                timeout: 2000,
                SnackbarText: `Verification OTP sent to ${this.Login.email_id.toLocaleLowerCase()}`,
              };

              this.counter = 60;

              // this.$refs.autoFocusOtpField.reset();
              setTimeout(() => {
                this.IsOTPEnabled = true;
                this.loginLoader = false;
                // setTimeout(() => {
                //   this.$refs.autoFocusOtpField.focus();
                // }, 100);
              }, 1000);

              this.$forceUpdate();
              // this.startCounter();
            }
          } catch (error) {
            console.log("erro", error);
            if (error.message == "There is already a signed in user.") {
              this.loginLoader = true;
              await signOut({ global: true });
              this.signInMethod("SEND_OTP");
            } else {
              this.loginLoader = false;
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "redColorVariant3",
                timeout: 2000,
                SnackbarText: error.message,
              };
            }

            // this.SnackbarComponent = {
            //   SnackbarVmodel: true,
            //   SnackbarColor: "red",
            //   SnackbarText: error.message,
            // };

            // console.log("Err", error.message);
          }
          break;
        case "VERIFY_OTP":
          try {
            this.loginLoader = true;
            console.log("coming In");
            await confirmSignIn({ challengeResponse: this.Login.otp })
              .then((res) => {
                this.SnackbarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "green",
                  SnackbarText: "Login Successfull",
                };
                console.log("yes");
                this.IsOTPFieldEnabled = false;
                this.IsSessionLoggedOut = false;
                localStorage.setItem("IsLoggedOut", "false");
                this.loginLoader = false;
                this.ActivateMethod();
              })
              .catch((err) => {
                if (err) {
                  this.counter = 0;
                  this.Login.otp = "";
                }
                this.SnackbarComponent.SnackbarVmodel = false;
                this.SnackbarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  SnackbarText: err.message,
                };
                this.OTPErrorMessage = err.message;
                this.loginLoader = false;
              });
          } catch (error) {
            console.log("err", error);
          }
          break;
      }
    },
    ActivateMethod() {
      sessionStorage.setItem("user_email_id", this.Login.email_id);
      setTimeout(() => {
        this.$router.push("/LandingPage");
      }, 1000);
    },
  },
};
</script>

<style></style>

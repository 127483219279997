import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/MainPages/LoginPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MainPages/LandingPage.vue"
      ),
    children: [
      {
        path: "/Admins",
        name: "Admins",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Admins.vue"
          ),
      },
      {
        path: "/Technician",
        name: "Technician",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Technician.vue"
          ),
      },
      {
        path: "/Cardiologist",
        name: "Cardiologist",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Cardiologist.vue"
          ),
      },
      {
        path: "/CardioTechnician",
        name: "CardioTechnician",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/CardioTechnician.vue"
          ),
      },
      {
        path: "/Patients",
        name: "Patients",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/AppUsers.vue"
          ),
      },
      {
        path: "/Reports",
        name: "Reports",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Reports.vue"
          ),
      },
      {
        path: "/MyProfile",
        name: "MyProfile",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/MyProfile.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { Amplify } from "aws-amplify";
loadFonts();

async function initApp() {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: "us-east-1_CsRhgL3MB",
        userPoolClientId: "5qn52k1h2l718k8d77k1u1mva9",
        // region: process.env.VUE_APP_AWS_REGION,
        // authenticationFlowType: "USER_PASSWORD_AUTH",
        signUpVerificationMethod: "code",
        loginWith: {
          email: true,
        },
      },
    },

    API: {
      GraphQL: {
        endpoint:
          "https://32xqzlctqjhyhh727vl3ru7vfm.appsync-api.us-east-1.amazonaws.com/graphql",
        region: "us-east-1",
        defaultAuthMode: "userPool",
      },
      // aws_project_region: "us-east-1",
      // aws_appsync_graphqlEndpoint:
      //   "https://fqg3l2urmfbp3ep5ahukj6xiga.appsync-api.us-east-1.amazonaws.com/graphql",
      // aws_appsync_authenticationType: "userpool",
      // defaultAuthMode: "userpool",
    },
  });
}

initApp().then(() => {
  createApp(App).use(router).use(store).use(vuetify).mount("#app");
});

// createApp(App)
//   .use(router)
//   .use(store)
//   .use(vuetify)
//   .mount('#app')

// import { createApp } from "vue";
// import App from "./App.vue";
// import router from "./router";
// import store from "./store";
// import vuetify from "./plugins/vuetify";
// import { loadFonts } from "./plugins/webfontloader";
// import { Amplify } from "aws-amplify";
// loadFonts();

// async function initApp() {
//   Amplify.configure({
//     Auth: {
//       Cognito: {
//         userPoolId: "us-east-1_CsRhgL3MB",
//         userPoolClientId: "5qn52k1h2l718k8d77k1u1mva9",
//         loginWith: {
//           email: true,
//         },
//         // signUpVerificationMethod: "code",
//       },
//     },
//     API: {
//       GraphQL: {
//         endpoint:
//           "https://32xqzlctqjhyhh727vl3ru7vfm.appsync-api.us-east-1.amazonaws.com/graphql",
//         region: "us-east-1",
//         defaultAuthMode: "userPool",
//       },
//     },
//   });
// }
// initApp().then(() => {
//   createApp(App).use(router).use(store).use(vuetify).mount("#app");
// });
